import React, { useEffect } from "react";
import { useAppContext } from "../../context/appContext";
import { Table } from "../../components";
import TableActions from "../../components/TableActions";
import SearchContainer from "../../components/SearchContainer";
import ModalForm from "../../components/ModalForm";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import {
  deleteIventoriesItem,
  getAllInventories,
  setEditIventoriesItem,
} from "../../context/actions/inventories";
import AddEditInventoryForm from "../../components/Inventories/AddEditInventoryForm";
import { getDevices } from "../../context/actions";
import { getAllStockItems } from "../../context/actions/stockItem";
const Inventory = () => {
  const {
    dispatch,
    user,
    page,
    search,
    searchStatus,
    sort,
    showModal,
    inventories,
    totalInventories,
    inventoryCheckFrequencyOptions
  } = useAppContext(); //get state from app context store

  useEffect(() => {
    getDevices(dispatch, user.id)
    getAllStockItems(dispatch, user.id)
    getAllInventories(dispatch, user.id, { page, search, searchStatus, sort });
    // eslint-disable-next-line
  }, []);

  const refreshStockItemTable = () => {
    getAllInventories(dispatch, user.id, { page, search, searchStatus, sort });
  };

  const setEditStockItemHandler = (id) => {
    setEditIventoriesItem(dispatch, id);
  };

  const deleteStockItemHandler = (id) => {
    deleteIventoriesItem(dispatch, user.id, id);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: (row) => `${row.device.name}-${row.stockItem.stockCode}`,
      },
      {
        Header: "Current Stock",
        accessor: "currentStock",
      },
      {
        Header: "Reorder Point",
        accessor: "reorderPoint",
      },
      {
        Header: "Reorder Amount",
        accessor: "reorderAmount",
      },
      {
        Header: "Check Frequency",
        accessor: "stockCheckFrequency",
        Cell: ({ cell: { value } }) => (
          inventoryCheckFrequencyOptions[value - 1]
        ),
      },
      {
        Header: "Device",
        accessor: "device",
        Cell: ({ cell: { value } }) => (
          value.name
        ),
      },
      {
        Header: "Stock Item",
        accessor: "stockItem",
        Cell: ({ cell: { value } }) => (
          value.stockCode
        ),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ cell: { value } }) => (
          <TableActions
            domainName="stock"
            _id={value}
            editDomain={setEditStockItemHandler}
            deleteDomain={deleteStockItemHandler}
          />
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  return (
    <Wrapper>
      <h3>Inventories</h3>
      <ModalForm modalIsOpen={showModal}>
        <AddEditInventoryForm />
      </ModalForm>
      <SearchContainer refreshDomain={refreshStockItemTable} />
      <Table
        columns={columns}
        data={inventories}
        domain="Inventories"
        totalItems={totalInventories}
      />
    </Wrapper>
  );
};

export default Inventory;
