import { actionTypes } from "../actionsTypes";
import { clearAlert, logoutUser } from "../actions";
import { authFetch } from "../appContext";
/*
newIventoriesItem: {
    quantityToOrder: number,
    inventoryId: number;
    timestamp: Date | number
    recipient: string (TODO)
}
*/

export const createReorder = async (dispatch, newReorder) => {
  dispatch({ type: actionTypes.EXECUTE_NEW_REQUEST });
  try {
    await authFetch.post("/reorderRequests", {timestamp: new Date(), ...newReorder});
    dispatch({ type: actionTypes.CREATE_INVENTORY_SUCCESS });
  } catch (error) {
    if (error.response.status === 401) return;
    dispatch({
      type: actionTypes.CREATE_INVENTORY_ERROR,
      payload: { msg: error.response.data.msg },
    });
  }
  clearAlert(dispatch);
};


export const getAllReorders = async (dispatch, userId, searchQuery={}) => {
    dispatch({ type: actionTypes.EXECUTE_NEW_REQUEST });
    const { page=1, search="", searchStatus="", sort="" } = searchQuery;
    let url = `/reorderRequests/users/${userId}?page=${page}&status=${searchStatus}&sort=${sort}`;
    if (search) {
      url += `search=${search}`;
    }
    try {
      const res = await authFetch.get(url);
      console.log(res.data);
      const data = res.data;
      const { reorderRequests, numOfPages = 1 } = data;
      dispatch({
        type: actionTypes.GET_REORDERS_SUCCESS,
        payload: {
          reorders: reorderRequests,
          totalReorders: reorderRequests.length,
          numOfPages,
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: actionTypes.GET_REORDERS_ERROR,
      });
      //logoutUser(dispatch);
    }
    clearAlert(dispatch);
  };
  
export const setEditReorder = (dispatch, inventoryId) => {
  dispatch({
    type: actionTypes.SET_EDIT_REORDERS,
    payload: inventoryId,
  });
};

export const editReorder = async (dispatch, userId, inventory) => {
  dispatch({ type: actionTypes.EXECUTE_NEW_REQUEST });
  const { id: inventoryId, ...inventoriesItemToBeUpdated } = inventory;
  try {
    await authFetch.patch(`/reorderRequests/${inventoryId}`, { ...inventoriesItemToBeUpdated });
    dispatch({ type: actionTypes.EDIT_REORDERS_SUCCESS });
    dispatch({ type: actionTypes.HANDLE_CLEAR_FORM_VALUES }); //clear form values
    getAllReorders(dispatch, userId);
  } catch (error) {
    if (error.response.status === 401) return; //error handled by axios interceptor
    dispatch({
      type: actionTypes.EDIT_REORDERS_ERROR,
      payload: { msg: error.response.data.msg },
    });
  }
  clearAlert(dispatch);
};

export const deleteReorder = async (dispatch, userId, stockItemId) => {
  dispatch({ type: actionTypes.EXECUTE_NEW_REQUEST });
  try {
    await authFetch.delete(`/reorderRequests/${stockItemId}`);
    getAllReorders(dispatch, userId);
  } catch (error) {
    logoutUser(dispatch);
  }
  clearAlert(dispatch);
};
