import { useState } from "react";
import { FormRow, Alert } from "../";
import { useAppContext } from "../../context/appContext"
import Wrapper from "../../assets/wrappers/AddEditDeviceForm";
import { displayAlert, closeModal } from "../../context/actions";
import { createInventory, editIventoriesItem } from "../../context/actions/inventories";
import FormRowSelect from "../FormRowSelect";

/*
newIventoriesItem: {
    currentStock: number,
    reorderPoint: number (float)
    reorderAmount: number (float)
    stockCheckFrequency: number
    deviceId: number;
    stockItemId: number
}
*/

const AddEditInventoryForm = () => {
  const { dispatch, showAlert, isLoading, isEditing, editInventoryObj, user, devices, stockItems, inventoryCheckFrequencyOptions } = useAppContext();
  const [inventory, setInventory] = useState({
    currentStock: editInventoryObj.currentStock || -1,
    reorderPoint: editInventoryObj.reorderPoint || 1,
    reorderAmount: editInventoryObj.reorderAmount || 1,
    stockCheckFrequency: editInventoryObj.stockCheckFrequency || 1,
    deviceId: editInventoryObj.deviceId || devices[0].id,
    stockItemId: editInventoryObj.stockItemId || stockItems[0].id
  });
  const { currentStock, reorderPoint, reorderAmount, stockCheckFrequency, deviceId, stockItemId } = inventory;

  const handleChange = (e) => {
    setInventory((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!reorderPoint || !reorderAmount || !stockCheckFrequency) {
      // test and remove temporary
      displayAlert(dispatch);
      return;
    }

    if(isEditing) {
      editIventoriesItem(dispatch, user.id, {id: editInventoryObj.id, ...inventory});
      setInventory({
        currentStock: 0,
        reorderPoint: 0.0,
        reorderAmount: 0.0,
        stockCheckFrequency: 1,
      });
      closeModal(dispatch);
      return;
    }
    createInventory(dispatch, inventory)
    setInventory({
      currentStock: 0,
      reorderPoint: 0.0,
      reorderAmount: 0.0,
      stockCheckFrequency: 1,
    });
  };

  const closeModalHandler = (e) => {
    e.preventDefault();
    closeModal(dispatch);
    setInventory({
      currentStock: 0,
      reorderPoint: 0.0,
      reorderAmount: 0.0,
      stockCheckFrequency: 1,
    });
  }

  return (
    <Wrapper>
      <form className="form" onSubmit={handleSubmit}>
        <h3>{isEditing ? "Edit Inventory": "Add Inventory"} </h3>
        {showAlert && <Alert />}

        <FormRowSelect
            labelText='Scale'
            name='deviceId'
            value={deviceId}
            handleChange={handleChange}
            list={devices.map((device)=> {
              return device.name
            })}
            values={devices.map((device)=> {
              return device.id
            })}
        />

        <FormRowSelect
            labelText='Stock Item'
            name='stockItemId'
            value={stockItemId}
            handleChange={handleChange}
            list={stockItems.map((stockItem)=> {
              return stockItem.stockCode
            })}
            values={stockItems.map((stockItem)=> {
              return stockItem.id
            })}
        />

        <FormRowSelect
          labelText='Check Frequency'
          name='stockCheckFrequency'
          value={stockCheckFrequency}
          handleChange={handleChange}
          list={inventoryCheckFrequencyOptions}
          values={[1, 2, 3, 4, 5]}
          disabled={true}
        />

        <div className="form-center">
          <FormRow
            type="number"
            name="currentStock"
            labelText="Current Stock"
            value={currentStock}
            handleChange={handleChange}
            disabled={true}
          />

          <FormRow
            type="number"
            name="reorderPoint"
            labelText="Reorder Point"
            value={reorderPoint}
            handleChange={handleChange}
          />

          <FormRow
            type="number"
            name="reorderAmount"
            labelText="Reorder Amount"
            value={reorderAmount}
            handleChange={handleChange}
          />
  
          <button className="btn " type="submit" disabled={isLoading}>
            {isLoading ? "Please Wait..." : "save changes"}
          </button>

          <button className="btn  clear-btn" onClick={closeModalHandler}>
            Close
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default AddEditInventoryForm;
