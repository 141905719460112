import React, { useEffect } from "react";
import { useAppContext } from "../../context/appContext";

import { Table } from "../../components";

import TableActions from "../../components/TableActions";

import SearchContainer from "../../components/SearchContainer";

import ModalForm from "../../components/ModalForm";


import Wrapper from "../../assets/wrappers/DashboardFormPage";
import AddEditStockItemForm from "../../components/StockItems/AddEditStockItemForm";
import { deleteStockItem, getAllStockItems, setEditStockItem } from "../../context/actions/stockItem";
const StockItems = () => {
    const { dispatch, user, stockItems, page, search, searchStatus, sort, showModal,totalStockItems } = useAppContext(); //get state from app context store
    useEffect(() => {
      getAllStockItems(dispatch, user.id, {page, search, searchStatus, sort})
      // eslint-disable-next-line
    }, [page, search, searchStatus, sort]);
    
    const refreshStockItemTable = () => {
      getAllStockItems(dispatch, user.id, { page, search, searchStatus, sort });
    }

    const setEditStockItemHandler = (id) => {
      setEditStockItem(dispatch, id);
    }
    
    const deleteStockItemHandler = (id) => {
      deleteStockItem(dispatch, user.id, id);
    }
    
    const columns = React.useMemo(
      () => [
        {
          Header: "Stock Code",
          accessor: "stockCode",
        },
        {
          Header: "Unit Weight",
          accessor: "unitWeight",
        },
        {
          Header: "Packaging Weight",
          accessor: "packagingWeight",
        },
        {
          Header: "Actions",
          accessor: "id",
          Cell: ({ cell: { value } }) => <TableActions 
          domainName="stock"
          _id={value} 
          editDomain={setEditStockItemHandler} 
          deleteDomain={deleteStockItemHandler}/>,
        },
      ],
      // eslint-disable-next-line 
      []
    );
  return (
    <Wrapper>
      <h3>stock items </h3>
      <ModalForm modalIsOpen={showModal}>
        <AddEditStockItemForm />
      </ModalForm>
      <SearchContainer refreshDomain={refreshStockItemTable}/>
      <Table columns={columns} data={stockItems} domain="Stock Items" totalItems={totalStockItems} />
    </Wrapper>
  );
};

export default StockItems;
