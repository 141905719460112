import { useState } from "react";
import { FormRow, FormRowSelect, Alert } from "./";
import { useAppContext } from "../context/appContext"
import Wrapper from "../assets/wrappers/AddEditDeviceForm";
import { displayAlert, closeModal, createDevice, editDevice } from "../context/actions";

const AddEditDeviceForm = () => {
  const { dispatch, deviceUnitOptions, showAlert, isLoading, isEditing, editDeviceObj, user } = useAppContext();

  const [device, setDevice] = useState({
    name: editDeviceObj.name || '',
    sn: editDeviceObj.sn,
    token: editDeviceObj.token,
    unit: editDeviceObj.unit
  });
  const { name = "", sn = "", token = "", unit = deviceUnitOptions[0] } = device;

  const handleChange = (e) => {
    setDevice((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!name || (isEditing && !token)) {
      if (!name || !sn || !unit) {

      // test and remove temporary
      displayAlert(dispatch);
      return;
    }
    if(isEditing) {
      editDevice(dispatch, user.id, {id: editDeviceObj.id, name, sn, token, unit});
      setDevice({
        name: "",
        sn: "",
        token: "",
        unit: deviceUnitOptions[0]
      });
      closeModal(dispatch);
      return;
    }
    createDevice(dispatch, {name, sn, token, unit});
    setDevice({
      name: "",
      sn: "",
      token: "",
      type: deviceUnitOptions[0]
    });
  };

  const closeModalHandler = (e) => {
    e.preventDefault();
    closeModal(dispatch);
    setDevice({
      name: "",
      mac: "",
      token: "",
      unit: deviceUnitOptions[0]
    });
  }

  return (
    <Wrapper>
      <form className="form" onSubmit={handleSubmit}>
        <h3>{isEditing ? "Edit Device": "Add Device"} </h3>
        {showAlert && <Alert />}

        {/* name */}
        <div className="form-center">
          <FormRow
            type="text"
            name="name"
            value={name}
            handleChange={handleChange}
          />

          <FormRow
            type="text"
            name="sn"
            labelText="SN"
            value={sn}
            handleChange={handleChange}
          />

          <FormRowSelect
            labelText='unit'
            name='unit'
            value={unit}
            handleChange={handleChange}
            list={deviceUnitOptions}
          />

          {/* {
            isEditing && (<FormRow
            type="text"
            name="token"
            value={token}
            handleChange={handleChange}
          />)

          } */}
          <button className="btn " type="submit" disabled={isLoading}>
            {isLoading ? "Please Wait..." : "save changes"}
          </button>

          <button className="btn  clear-btn" onClick={closeModalHandler}>
            Close
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default AddEditDeviceForm;
