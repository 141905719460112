import React, { useEffect } from "react";
import moment from "moment";
import { useAppContext } from "../../context/appContext";
import { Table } from "../../components";
import TableActions from "../../components/TableActions";
import { deleteDevice, getDevices, setEditDevice } from "../../context/actions";
import { getUTCOffsetFromTimezoneString } from "../../util/timezoneList";
import SearchContainer from "../../components/SearchContainer";
import ModalForm from "../../components/ModalForm";
import AddEditDeviceForm from "../../components/AddEditDeviceForm";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import DeviceTokenActions from "../../components/DeviceTokenActions";
const Device = () => {
  const { dispatch, user, devices, page, search, searchStatus, sort, showModal, totalDevices, userLocation } = useAppContext(); //get state from app context store
  useEffect(() => {
    getDevices(dispatch, user.id, {page, search, searchStatus, sort});
    // eslint-disable-next-line
  }, [page, search, searchStatus, sort]);

  const refreshDeviceTable = () => {
    getDevices(dispatch, user.id, {page, search, searchStatus, sort});
  }

  const utcOffset = React.useMemo(
    () => getUTCOffsetFromTimezoneString(userLocation),
    // eslint-disable-next-line 
    []
  );

  const setEditDeviceHandler = (id) => {
    setEditDevice(dispatch, id);
  }
  
  const deleteDeviceHandler = (id) => {
    deleteDevice(dispatch, user.id, id);
  }
  
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      // {
      //   Header: "Model",
      //   accessor: "model",
      // },
      {
        Header: "SN",
        accessor: "sn",
      },
      {
        Header: "Unit",
        accessor: "unit",
      },
      {
        Header: "Current Weight",
        accessor: (row) => `${(row.reads.length > 0) ? row.reads[0].value : 'NA'}`,
      },
      {
        Header: "Status",
        accessor: "isOnline",
        Cell: ({ cell: { value } }) => {
          return value === true ? <p>Connected</p> : <p>Disconnected</p>;
        },
      },
      {
        Header: "Last Report",
        accessor: "lastHeartbeat",
        Cell: ({ cell: { value } }) => {
          let lasDisconnectedDate = moment(value).utcOffset(utcOffset);
          lasDisconnectedDate = lasDisconnectedDate.format(
            "YYYY-MM-DD HH:mm:ss"
          );
          return lasDisconnectedDate;
        },
      },
      {
        Header: "Token",
        accessor: (row) => `${row.id}`,
        Cell: ({ cell: { value }}) => <DeviceTokenActions deviceId={value} />
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ cell: { value } }) => <TableActions _id={value}
        editDomain={setEditDeviceHandler}
        deleteDomain={deleteDeviceHandler}
        />,
      },
    ],
    // eslint-disable-next-line 
    []
  );

  return (
    <Wrapper>
    <h3>devices </h3>
    <ModalForm modalIsOpen={showModal}>
      <AddEditDeviceForm />
    </ModalForm>
      <SearchContainer refreshDomain={refreshDeviceTable} />
      <Table columns={columns} data={devices} totalItems={totalDevices} />
    </Wrapper>
  );
};

export default Device;
