import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiOutlineCopy, AiOutlineSync } from "react-icons/ai";
import Wrapper from "../assets/wrappers/DeviceTokenActions";
import { updateDeviceToken } from "../context/actions";
import { useAppContext } from "../context/appContext";

const DeviceTokenActions = ({ deviceId }) => {
  const { dispatch, devices, user } = useAppContext();
  const [isCopied, setIsCopied] = useState(false);
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    setShowCopyMessage(true);

    setTimeout(() => {
      setIsCopied(false);
      setShowCopyMessage(false);
    }, 1000); // Change the color and hide the message after 1 second (adjust the delay as needed)
  };

  useEffect(() => {
    if (showCopyMessage) {
      setTimeout(() => {
        setShowCopyMessage(false);
      }, 3000); // Hide the message after 3 seconds (adjust the delay as needed)
    }
  }, [showCopyMessage]);
  
  return (
    <Wrapper>
      <div className="actions">
        <div className="label">
          {
            devices.find(({ id }) => id === parseInt(deviceId)).deviceToken.token
          }
        </div>
        <div className="action">
          {showCopyMessage && <span className="copy-message">Copied!</span>}
          <CopyToClipboard text={devices.find(({ id }) => id === parseInt(deviceId)).deviceToken.token} onCopy={handleCopy}>
            <span className="icon">
              {isCopied ? (
                <AiOutlineCopy style={{ color: "green" }} />
              ) : (
                <AiOutlineCopy style={{ color: "var(--grey-400)" }} />
              )}
            </span>
          </CopyToClipboard>
        </div>
        <div className="action">
          <span className="icon" onClick={() => {updateDeviceToken(dispatch, user.id, deviceId)}}>
            {<AiOutlineSync />}
          </span>
        </div>
      </div>
      <div className="actions"></div>
    </Wrapper>
  );
};

export default DeviceTokenActions;
