import { useState } from "react";
import { FormRow, Alert } from "../";
import FormRowSelect from "../FormRowSelect";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/AddEditDeviceForm";
import { displayAlert, closeModal } from "../../context/actions";
import { createReorder, editReorder } from "../../context/actions/Reorders";

const AddEditReordersForm = () => {
  const {
    dispatch,
    showAlert,
    isLoading,
    isEditing,
    editReorderObj,
    user,
    inventories,
    reorders,
  } = useAppContext();

  const [reorderRequest, setReorderRequest] = useState({
    inventoryId: editReorderObj.inventoryId || inventories[0].id,
    quantityToOrder:
      editReorderObj.quantityToOrder || inventories[0].reorderAmount,
    recipient: editReorderObj.recipient || "",
  });
  const { quantityToOrder, inventoryId, recipient } = reorderRequest;

  const handleChange = (e) => {
    setReorderRequest((prevState) => {
      if (e.target.name === "inventoryId") {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
          quantityToOrder: inventories.find(({ id }) => id === parseInt(e.target.value)).reorderAmount
        };
      } else {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!quantityToOrder || !inventoryId || !recipient) {
      // test and remove temporary
      displayAlert(dispatch);
      return;
    }
    if (isEditing) {
      reorderRequest.recipient =
        editReorderObj.recipient ||
        reorders[0].emailNotification.emailRecipient;
      editReorder(dispatch, user.id, {
        id: editReorderObj.id,
        ...reorderRequest,
      });
      setReorderRequest({
        quantityToOrder: 0,
        inventoryId: 0,
        recipient: "",
      });
      closeModal(dispatch);
      return;
    }
    createReorder(dispatch, reorderRequest);
    setReorderRequest({
      quantityToOrder: 0,
      inventoryId: 0,
      recipient: "",
    });
  };

  const closeModalHandler = (e) => {
    e.preventDefault();
    closeModal(dispatch);
    setReorderRequest({
      quantityToOrder: 0,
      inventoryId: 0,
      recipient: "",
    });
  };

  return (
    <Wrapper>
      <form className="form" onSubmit={handleSubmit}>
        <h3>{isEditing ? "Edit Reorder Request" : "Add Reorder Request"} </h3>
        {showAlert && <Alert />}

        {/* name */}

        <FormRowSelect
          labelText="Select an Inventory"
          name="inventoryId"
          value={inventoryId}
          handleChange={handleChange}
          list={inventories.map((inventory) => {
            return `${inventory.device.name}-${inventory.stockItem.stockCode}`;
          })}
          values={inventories.map((inventory) => {
            return inventory.id;
          })}
        />

        <div className="form-center">
          <FormRow
            type="text"
            name="quantityToOrder"
            labelText="Quantity To Order"
            value={quantityToOrder}
            disabled={true}
            //handleChange={handleChange}
          />

          <FormRow
            type="email"
            name="recipient"
            labelText="recipient"
            value={recipient}
            handleChange={handleChange}
          />

          <button className="btn " type="submit" disabled={isLoading}>
            {isLoading ? "Please Wait..." : "save changes"}
          </button>

          <button className="btn  clear-btn" onClick={closeModalHandler}>
            Close
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default AddEditReordersForm;
