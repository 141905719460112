import { actionTypes } from "../actionsTypes";
import { clearAlert, logoutUser } from "../actions";
import { authFetch } from "../appContext";
/*
newStockItem: {
    stockCode: string,
    unitWeight: number
    packagingWeight: number
}
*/

export const createStockItem = async (dispatch, newStockItem) => {
  dispatch({ type: actionTypes.EXECUTE_NEW_REQUEST });
  //const { name="", sn="", unit=1, token="", utc=0, model="NA_MODEL"} = newDevice;

  try {
    await authFetch.post("/stockItems", newStockItem);
    dispatch({ type: actionTypes.CREATE_STOCKITEM_SUCCESS });
  } catch (error) {
    if (error.response.status === 401) return;
    dispatch({
      type: actionTypes.CREATE_STOCKITEM_ERROR,
      payload: { msg: error.response.data.msg },
    });
  }
  clearAlert(dispatch);
};


export const getAllStockItems = async (dispatch, userId, searchQuery={}) => {
    dispatch({ type: actionTypes.EXECUTE_NEW_REQUEST });
    const { page=1, search="", searchStatus="", sort="" } = searchQuery;
    let url = `/stockItems/users/${userId}?page=${page}&status=${searchStatus}&sort=${sort}`;
    if (search) {
      url += `search=${search}`;
    }
    try {
      const res = await authFetch.get(url);
      console.log(res.data);
      const data = res.data;
      const { stockItems, numOfPages = 1 } = data;
      dispatch({
        type: actionTypes.GET_STOCKITEM_SUCCESS,
        payload: {
          stockItems,
          totalStockItems: stockItems.length,
          numOfPages,
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: actionTypes.GET_STOCKITEM_ERROR,
      });
      //logoutUser(dispatch);
    }
    clearAlert(dispatch);
  };
  
export const setEditStockItem = (dispatch, stockItemId) => {
  dispatch({
    type: actionTypes.SET_EDIT_STOCKITEM,
    payload: stockItemId,
  });
};

export const editStockItem = async (dispatch, userId, stockItem) => {
  dispatch({ type: actionTypes.EXECUTE_NEW_REQUEST });
  const { id: stockItemId, ...stockItemBeUpdated } = stockItem;
  try {
    await authFetch.patch(`/stockItems/${stockItemId}`, { ...stockItemBeUpdated });
    dispatch({ type: actionTypes.EDIT_STOCKITEM_SUCCESS });
    dispatch({ type: actionTypes.HANDLE_CLEAR_FORM_VALUES }); //clear form values
    getAllStockItems(dispatch, userId);
  } catch (error) {
    if (error.response.status === 401) return; //error handled by axios interceptor
    dispatch({
      type: actionTypes.EDIT_STOCKITEM_ERROR,
      payload: { msg: error.response.data.msg },
    });
  }
  clearAlert(dispatch);
};

export const deleteStockItem= async (dispatch, userId, stockItemId) => {
  dispatch({ type: actionTypes.EXECUTE_NEW_REQUEST });
  try {
    await authFetch.delete(`/stockItems/${stockItemId}`);
    getAllStockItems(dispatch, userId);
  } catch (error) {
    logoutUser(dispatch);
  }
  clearAlert(dispatch);
};
