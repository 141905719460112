import styled from "styled-components";

const Wrapper = styled.section`
text-align: left;
h4{
    text-align: left;
    margin-left: 38px;
}
.chart-container {
    margin-bottom: 38px;
}
.chart {
    margin-top: -42px;
}
`;
export default Wrapper;
