import { AiOutlineBarcode, AiOutlineShop, AiOutlineRedo, AiOutlineWifi } from "react-icons/ai";
import { ImProfile } from "react-icons/im";

const links = [
  { id: 1, text: "devices", path: "/", icon: <AiOutlineWifi /> },
  { id: 2, text: "Stock Items", path: "/stock", icon: <AiOutlineBarcode /> },
  { id: 3, text: "Inventory", path: "/inventory", icon: <AiOutlineShop /> },
  { id: 4, text: "Reorders", path: "/reorders", icon: <AiOutlineRedo /> },
  { id: 5, text: "profile", path: "profile", icon: <ImProfile /> },
];

export default links;
