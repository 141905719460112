import styled from "styled-components";

const Wrapper = styled.main`
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--white); // Set the table background to white
    border: 1px solid var(--grey-200);
    color: var(--grey-800);
  }
  
  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid var(--grey-200);
    border-right: 0.1px solid var(--grey-300); // Add vertical lines between columns
  }
  
  th {
    background-color: var(--primary-800); // Set the background color for table headers
    color: var(--white); // Set the text color for table headers
    font-weight: bold;
  }
  
  td {
    color: var(--grey-500); // Set the text color for table data to a lighter gray
  }
  
  tbody {
    border-bottom: 1px solid var(--grey-200);
  }

  tr {
    transition: background-color 0.2s;
  }

  tr:hover {
    background-color: var(--grey-50); // Set the background color on hover to a light gray
  }
`;

export default Wrapper;
