import { useState } from "react";
import { FormRow, Alert } from "../";
import { useAppContext } from "../../context/appContext"
import Wrapper from "../../assets/wrappers/AddEditDeviceForm";
import { displayAlert, closeModal } from "../../context/actions";
import { createStockItem, editStockItem } from "../../context/actions/stockItem";

const AddEditStockItemForm = () => {
  const { dispatch, showAlert, isLoading, isEditing, editStockItemObj, user } = useAppContext();

  const [stockItem, setStockItem] = useState({
    stockCode: editStockItemObj.stockCode || '',
    unitWeight: editStockItemObj.unitWeight || 0,
    packagingWeight: editStockItemObj.packagingWeight || 0.00,
  });
  const { stockCode = '', unitWeight= 0, packagingWeight= 0 } = stockItem;

  const handleChange = (e) => {
    setStockItem((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!stockCode || !unitWeight || !packagingWeight) {
      // test and remove temporary
      displayAlert(dispatch);
      return;
    }
    if(isEditing) {
      editStockItem(dispatch, user.id, {id: editStockItemObj.id, ...stockItem});
      setStockItem({
        stockCode: "",
        unitWeight: 0,
        packagingWeight: 0.00,
      });
      closeModal(dispatch);
      return;
    }
    createStockItem(dispatch, stockItem)
    setStockItem({
      stockCode: "",
      unitWeight: 0,
      packagingWeight: 0.00,
    });
  };

  const closeModalHandler = (e) => {
    e.preventDefault();
    closeModal(dispatch);
    setStockItem({
      stockCode: "",
      unitWeight: 0,
      packagingWeight: 0.00,
    });
  }

  return (
    <Wrapper>
      <form className="form" onSubmit={handleSubmit}>
        <h3>{isEditing ? "Edit Stock Item": "Add Stock Item"} </h3>
        {showAlert && <Alert />}

        {/* name */}
        <div className="form-center">
          <FormRow
            type="text"
            name="stockCode"
            labelText="Stock Code"
            value={stockCode}
            handleChange={handleChange}
          />

          <FormRow
            type="number"
            name="unitWeight"
            labelText="Unit Weight"
            value={unitWeight}
            handleChange={handleChange}
          />

          <FormRow
            type="number"
            name="packagingWeight"
            labelText="Packaging Weight"
            value={packagingWeight}
            handleChange={handleChange}
          />

  
          <button className="btn " type="submit" disabled={isLoading}>
            {isLoading ? "Please Wait..." : "save changes"}
          </button>

          <button className="btn  clear-btn" onClick={closeModalHandler}>
            Close
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default AddEditStockItemForm;
