import { actionTypes } from "./actionsTypes";

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.DISPLAY_ALERT:
      return {
        ...state,
        showAlert: true,
        alertType: "danger",
        alertText: "Please provide all values",
      };
    case actionTypes.CLEAR_ALERT:
      return {
        ...state,
        showAlert: false,
        alertType: "",
        alertText: "",
      };
    case actionTypes.SHOW_MODAL:
      return {
        ...state,
        showModal: true,
      };
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
        isEditing: false,
        editDeviceId: "",
        editDeviceObj: {},
        editStockItemObj: {},
        editStockItemId: "",
      };
    case actionTypes.TOGGLE_SIDEBAR:
      return {
        ...state,
        showSidebar: !state.showSidebar,
      };
    case actionTypes.HANDLE_FORM_INPUT_CHANGE:
      return {
        ...state,
        page: 1,
        [action.payload.name]: action.payload.value,
      };
    case actionTypes.HANDLE_CLEAR_FORM_VALUES:
      return {
        ...state,
        isEditing: false,
        editDeviceId: "",
        editDeviceObj: {},
        editStockItemObj: {},
        editStockItemId: "",
      };
    case actionTypes.SEARCH_CLEAR_FILTERS:
      return {
        ...state,
        search: "",
        searchStatus: "all",
        searchType: "all",
        sort: "latest",
      };
    case actionTypes.EXECUTE_NEW_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        userLocation: action.payload.user.location,
        jobLocation: action.payload.user.location,
        isLoading: false,
        showAlert: true,
        alertType: "success",
        alertText: "User Created! Redirecting...",
      };
    case actionTypes.REGISTER_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        showAlert: true,
        alertType: "danger",
        alertText: action.payload.msg,
      };
    case actionTypes.LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        userLocation: action.payload.user.location,
        jobLocation: action.payload.user.location,
        isLoading: false,
        showAlert: true,
        alertType: "success",
        alertText: "Login Successful! Redirecting...",
      };
    case actionTypes.LOGIN_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        showAlert: true,
        alertType: "danger",
        alertText: action.payload.msg,
      };
    case actionTypes.LOGOUT_USER:
      return {
        ...state,
        user: null,
        token: null,
        isLoading: false,
        userLocation: "",
        jobLocation: "",
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: {...state.user, name: action.payload.username},
        userLocation: action.payload.timezone,
        isLoading: false,
        showAlert: true,
        alertType: "success",
        alertText: "User Profile Updated!",
      };
    case actionTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        showAlert: true,
        alertType: "danger",
        alertText: action.payload.msg,
      };
    case actionTypes.GET_DEVICES_SUCESS:
      return {
        ...state,
        devices: action.payload.devices,
        isLoading: false,
        totalDevices: action.payload.totalDevices,
        numOfPages: action.payload.numOfPages,
      };

    case actionTypes.CREATE_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        showAlert: true,
        alertType: "success",
        alertText: "New Device Created!",
      };
    case actionTypes.CREATE_DEVICE_ERROR:
      return {
        ...state,
        isLoading: false,
        showAlert: true,
        alertType: "danger",
        alertText: action.payload.msg,
      };
    case actionTypes.SET_EDIT_DEVICE:
      const deviceEdit = state.devices.find(
        ({ id }) => id === action.payload
      );
      return {
        ...state,
        isEditing: true,
        showModal: true,
        editDeviceId: action.payload,
        editDeviceObj: deviceEdit,
      };
    case actionTypes.EDIT_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        showAlert: true,
        alertType: "success",
        alertText: "Device Updated!",
      };
    case actionTypes.EDIT_DEVICE_ERROR:
      return {
        ...state,
        isLoading: false,
        showAlert: true,
        alertType: "danger",
        alertText: action.payload.msg,
      };
      // Stock Item - CREATE OK
      case actionTypes.CREATE_STOCKITEM_SUCCESS:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
          alertType: "success",
          alertText: "New Stock Item Created!",
        };
      // Stock Item - CREATE ERROR
      case actionTypes.CREATE_STOCKITEM_ERROR:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
          alertType: "danger",
          alertText: action.payload.msg,
        };
        case actionTypes.GET_STOCKITEM_SUCCESS:
          return {
            ...state,
            stockItems: action.payload.stockItems,
            isLoading: false,
            totalStockItems: action.payload.totalStockItems,
            numOfPages: action.payload.numOfPages,
          };
          case actionTypes.GET_STOCKITEM_ERROR:
            return {
              ...state,
              isLoading: false,
              showAlert: true,
            };
      case actionTypes.SET_EDIT_STOCKITEM:
        const stockItemEdit = state.stockItems.find(
          ({ id }) => id === action.payload
        );
        return {
          ...state,
          isEditing: true,
          showModal: true,
          editStockItemId: action.payload,
          editStockItemObj: stockItemEdit,
        };
      case actionTypes.EDIT_STOCKITEM_SUCCESS:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
          alertType: "success",
          alertText: "Device Updated!",
        };
      case actionTypes.EDIT_STOCKITEM_ERROR:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
          alertType: "danger",
          alertText: action.payload.msg,
        };
      // Inventory- CREATE OK
      case actionTypes.CREATE_INVENTORY_SUCCESS:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
          alertType: "success",
          alertText: "New Inventory Created!",
        };
      // Inventory - CREATE ERROR
      case actionTypes.CREATE_INVENTORY_ERROR:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
          alertType: "danger",
          alertText: action.payload.msg,
        };
      case actionTypes.GET_INVENTORIES_SUCCESS:
        return {
          ...state,
          inventories: action.payload.inventories,
          isLoading: false,
          totalInventories: action.payload.totalInventories,
          numOfPages: action.payload.numOfPages,
        };
      case actionTypes.GET_INVENTORIES_ERROR:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
        };
      case actionTypes.SET_EDIT_INVENTORY:
        const inventoryEdit = state.inventories.find(
          ({ id }) => id === action.payload
        );
        return {
          ...state,
          isEditing: true,
          showModal: true,
          editInventoryId: action.payload,
          editInventoryObj: inventoryEdit,
        };
      case actionTypes.EDIT_INVENTORY_SUCCESS:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
          alertType: "success",
          alertText: "Inventory Updated!",
        };
      case actionTypes.EDIT_INVENTORY_ERROR:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
          alertType: "danger",
          alertText: action.payload.msg,
        };
      // Reorder- CREATE OK
      case actionTypes.CREATE_REORDERS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
          alertType: "success",
          alertText: "New Reorder Created!",
        };
      // Reorder - CREATE ERROR
      case actionTypes.CREATE_REORDERS_ERROR:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
          alertType: "danger",
          alertText: action.payload.msg,
        };
      case actionTypes.GET_REORDERS_SUCCESS:
        return {
          ...state,
          reorders: action.payload.reorders,
          isLoading: false,
          totalReorders: action.payload.totalReorders,
          numOfPages: action.payload.numOfPages,
        };
      case actionTypes.GET_REORDERS_ERROR:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
        };
      case actionTypes.SET_EDIT_REORDERS:
        const reorderEdit = state.reorders.find(
          ({ id }) => id === action.payload
        );
        return {
          ...state,
          isEditing: true,
          showModal: true,
          editReorderId: action.payload,
          editReorderObj: reorderEdit,
        };
      case actionTypes.EDIT_REORDERS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
          alertType: "success",
          alertText: "Reorder Updated!",
        };
      case actionTypes.EDIT_REORDERS_ERROR:
        return {
          ...state,
          isLoading: false,
          showAlert: true,
          alertType: "danger",
          alertText: action.payload.msg,
        };
    case actionTypes.SHOW_STATS_BEGIN:
      return {
        ...state,
        isLoading: true,
        showAlert: false,
      };
    case actionTypes.SHOW_STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        voltageTimeSeries: action.payload.stats.voltage,
        amperageTimeSeries: action.payload.stats.current,
        energyTimeSeries: action.payload.stats.activeKwh,
        frequencyTimeSeries: action.payload.stats.frequencyTS,
        overview: action.payload.overview,
      };
    case actionTypes.UPDATE_STATS_REALTIME:
      if (state.voltageTimeSeries.length > 50) {
        state.voltageTimeSeries.pop();
      }
      if (state.amperageTimeSeries.length > 50) {
        state.amperageTimeSeries.pop();
      }
      if (state.energyTimeSeries.length > 50) {
        state.energyTimeSeries.pop();
      }
      if (state.frequencyTimeSeries.length > 50) {
        state.frequencyTimeSeries.pop();
      }
      return {
        ...state,
        isLoading: false,
        voltageTimeSeries: [
          {
            timestamp: new Date(action.payload.timestamp * 1000),
            value: action.payload.overview.lineVoltage,
          },
          ...state.voltageTimeSeries,
        ],
        amperageTimeSeries: [
          {
            timestamp: new Date(action.payload.timestamp * 1000),
            value: action.payload.overview.lineCurrent,
          },
          ...state.amperageTimeSeries,
        ],
        energyTimeSeries: [
          {
            timestamp: new Date(action.payload.timestamp * 1000),
            value: action.payload.overview.energy,
          },
          ...state.energyTimeSeries,
        ],
        frequencyTimeSeries: [
          {
            timestamp: new Date(action.payload.timestamp * 1000),
            value: action.payload.overview.frequency,
          },
          ...state.frequencyTimeSeries,
        ],
        overview: action.payload.overview,
      };
    case actionTypes.SET_SOCKET_CONNECTED:
      return {
        ...state,
        socketIsConnected: action.payload,
      };
    case actionTypes.SET_SOCKET_ROOM_ID:
      return {
        ...state,
        socketRoomId: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
