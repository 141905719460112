import { actionTypes } from "../actionsTypes";
import { clearAlert, logoutUser } from "../actions";
import { authFetch } from "../appContext";
/*
newIventoriesItem: {
    currentStock: number,
    reorderPoint: number (float)
    reorderAmount: number (float)
    stockCheckFrequency: number
    deviceId: number;
    stockItemId: number
}
*/

export const createInventory = async (dispatch, newInventory) => {
  dispatch({ type: actionTypes.EXECUTE_NEW_REQUEST });
  try {
    await authFetch.post("/inventories", newInventory);
    dispatch({ type: actionTypes.CREATE_INVENTORY_SUCCESS });
  } catch (error) {
    if (error.response.status === 401) return;
    dispatch({
      type: actionTypes.CREATE_INVENTORY_ERROR,
      payload: { msg: error.response.data.msg },
    });
  }
  clearAlert(dispatch);
};


export const getAllInventories = async (dispatch, userId, searchQuery={}) => {
    dispatch({ type: actionTypes.EXECUTE_NEW_REQUEST });
    const { page=1, search="", searchStatus="", sort="" } = searchQuery;
    let url = `/inventories/users/${userId}?page=${page}&status=${searchStatus}&sort=${sort}`;
    if (search) {
      url += `search=${search}`;
    }
    try {
      const res = await authFetch.get(url);
      console.log(res.data);
      const data = res.data;
      const { inventories, numOfPages = 1 } = data;
      dispatch({
        type: actionTypes.GET_INVENTORIES_SUCCESS,
        payload: {
          inventories,
          totalInventories: inventories.length,
          numOfPages,
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: actionTypes.GET_INVENTORIES_ERROR,
      });
      //logoutUser(dispatch);
    }
    clearAlert(dispatch);
  };
  
export const setEditIventoriesItem = (dispatch, inventoryId) => {
  dispatch({
    type: actionTypes.SET_EDIT_INVENTORY,
    payload: inventoryId,
  });
};

export const editIventoriesItem  = async (dispatch, userId, inventory) => {
  dispatch({ type: actionTypes.EXECUTE_NEW_REQUEST });
  const { id: inventoryId, ...inventoriesItemToBeUpdated } = inventory;
  try {
    await authFetch.patch(`/inventories/${inventoryId}`, { ...inventoriesItemToBeUpdated });
    dispatch({ type: actionTypes.EDIT_INVENTORY_SUCCESS });
    dispatch({ type: actionTypes.HANDLE_CLEAR_FORM_VALUES }); //clear form values
    getAllInventories(dispatch, userId);
  } catch (error) {
    if (error.response.status === 401) return; //error handled by axios interceptor
    dispatch({
      type: actionTypes.EDIT_STOCKITEM_ERROR,
      payload: { msg: error.response.data.msg },
    });
  }
  clearAlert(dispatch);
};

export const deleteIventoriesItem= async (dispatch, userId, stockItemId) => {
  dispatch({ type: actionTypes.EXECUTE_NEW_REQUEST });
  try {
    await authFetch.delete(`/inventories/${stockItemId}`);
    getAllInventories(dispatch, userId);
  } catch (error) {
    logoutUser(dispatch);
  }
  clearAlert(dispatch);
};
