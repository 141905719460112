import React, { useEffect } from "react";
import { useAppContext } from "../../context/appContext";
import { Table } from "../../components";
import TableActions from "../../components/TableActions";
import SearchContainer from "../../components/SearchContainer";
import ModalForm from "../../components/ModalForm";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import AddEditReordersForm from "../../components/Reorders/AddEditReordersForm";
import { deleteReorder, getAllReorders, setEditReorder } from "../../context/actions/Reorders";
import { getAllInventories } from "../../context/actions/inventories";

const Reorders = () => {
  const { dispatch, user, page, search, searchStatus, sort, showModal, reorders, totalReorders } = useAppContext(); //get state from app context store
  useEffect(() => {
    getAllInventories(dispatch, user.id, { page, search, searchStatus, sort });
    getAllReorders(dispatch, user.id, {page, search, searchStatus, sort})
    // eslint-disable-next-line
  }, [page, search, searchStatus, sort]);
  
  const refreshStockItemTable = () => {
    getAllReorders(dispatch, user.id, { page, search, searchStatus, sort });
  }

  const setEditReorderHandler = (id) => {
    setEditReorder(dispatch, id);
  }
  
  const deleteReorderHandler = (id) => {
    deleteReorder(dispatch, user.id, id);
  }
  
  const columns = React.useMemo(
    () => [
      {
        Header: "Inventory",
        accessor: (row) => `${row.inventory.device.name}-${row.inventory.stockItem.stockCode}`,
      },
      {
        Header: "Quantity To Order",
        accessor: "quantityToOrder",
      },
      {
        Header: "Recipient",
        accessor: (row) => `${row.emailNotification.emailRecipient}`,
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ cell: { value } }) => <TableActions 
        domainName="reorders"
        _id={value} 
        editDomain={setEditReorderHandler} 
        deleteDomain={deleteReorderHandler}/>,
      },
    ],
    // eslint-disable-next-line 
    []
  );
return (
  <Wrapper>
    <h3>Reorders </h3>
    <ModalForm modalIsOpen={showModal}>
      <AddEditReordersForm />
    </ModalForm>
    <SearchContainer refreshDomain={refreshStockItemTable}/>
    <Table columns={columns} data={reorders} domain="Reorders" totalItems={totalReorders} />
  </Wrapper>
);
}

export default Reorders