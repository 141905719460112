import styled from "styled-components";

const Wrapper = styled.nav`
  .logo-img {
    width: 55px;
    margin-top : 20px;
    margin-right: 10px;
    margin-bottom: 0.6rem;
  }
`;
export default Wrapper;
